import { InputSelectAutocompleteModule } from './../components/input-select-autocomplete/input-select-autocomplete.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherIconsModule } from './feather-icons/feather-icons.module';
import { MaterialModule } from './material.module';
import { InputSelectModule } from '../components/input-select/input-select.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskModule } from 'ngx-mask';
import {
  CurrencyMaskConfig,
  CurrencyMaskModule,
  CURRENCY_MASK_CONFIG,
} from 'ng2-currency-mask';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { DatatableModule } from '../components/datatable/datatable.module';
import { AlertComponent } from '../components/alert/alert.component';
import { MatInputModule } from '@angular/material/input';
import { WrapperTableComponent } from '../components/wrapper-table/wrapper-table.component';
import { InputTextModule } from '../components/input-text/input-text.module';
import { ChipComponent } from '../components/chip/chip.component';
import { CanDirective } from '../directives/can.directive';
import { BoletoStatusDescricaoPipe } from '../pipes/boleto-status-descricao.pipe';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { CustomPaginatorIntl } from '@/services/custom-paginator.service';
import { MatTabsModule } from '@angular/material/tabs';
import { NormalizePipe } from '../pipes/normalize.pipe';
import { FormatFileSizePipe } from '../pipes/format-file-size.pipe';
import { MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};

@NgModule({
  declarations: [
    AlertComponent,
    WrapperTableComponent,
    ChipComponent,
    CanDirective,
    BoletoStatusDescricaoPipe,
    NormalizePipe,
    FormatFileSizePipe,
  ],
  imports: [CommonModule, NgxMaskModule.forRoot()],
  exports: [
    FeatherIconsModule,
    InputSelectModule,
    MatDatepickerModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    InputSelectAutocompleteModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCheckboxModule,
    CurrencyMaskModule,
    NgxMaskModule,
    MaterialModule,
    // NgbModule,
    // DatatableModule,
    MatSlideToggleModule,
    AlertComponent,
    MatInputModule,
    WrapperTableComponent,
    InputTextModule,
    ChipComponent,
    CanDirective,
    BoletoStatusDescricaoPipe,
    MatPaginatorModule,
    MatTabsModule,
    NormalizePipe,
    FormatFileSizePipe,
    MatStepperModule,
    ScrollingModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl,
    },
  ],
})
export class SharedModule {}
